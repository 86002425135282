<template>
    <Header/>
    <SoftwareEngineeringheader/>
    <SoftewareEngineeringSection/>
    <Softwareicons/>
    <Footer/>
</template>

<script>

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import SoftewareEngineeringSection from '@/components/SoftewareEngineeringSection.vue'
import SoftwareEngineeringheader from '@/components/SoftwareEngineeringheader.vue'
import Softwareicons from '@/components/Softwareicons.vue'

export default {
  components: {
    Header,
    Footer,
    SoftewareEngineeringSection,
    SoftwareEngineeringheader,
    Softwareicons
  }
}
</script>