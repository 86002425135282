<template>
    <section id="Software" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>One place to find solution to all your software needs</h2>
          <p style="padding-top:20px">Software engineering services includes several careful processes. They begin from basic coding to testing, validation and follow ups by regular maintenance.</p>
          <p>With the onset of AI, Agile technologies and layouts or rules to build perfect coded software, we at Exceljuta provide our valuable clients with the latest software engineering solutions that rely, and function based on all the modern versions of tech ever updating its features on a day-to-day basis. Our focus in developing software is based on the requirements of the clients. It is to give the maximum feasible, product life-cycle based, maintainable and reliable applied solutions.</p>
        </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-3 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4 class="softwarehead">{{ obj.header }}</h4>
              <h6 class="softwaresubhead">{{obj.subtitile}}</h6>
                <p>{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/Application.jpg',
        header: 'APPLICATION DEVELOPMENT',
        subtitile: 'Perfect App service',
        caption: 'To keep up with the market demands of application development, a developer is required to be well-versed in all high-level languages. One is also supposed to be an expert in the product level life cycle and the different forms of development. We provide all the expert guidance and the follow up support.'
      },
      {
        path: 'services/System.jpg',
        header: 'SYSTEM DEVELOPMENT',
        subtitile: 'A complete process-driven approach',
        caption: 'A systems development includes several processes that aim at developing, designing and testing new software program. An effective developmental process undergoes the initial defining of structure to maintenance and evaluation.'
      },
      {
        path: 'services/Web.jpg',
        header: 'WEB DEVELOPMENT',
        subtitile: 'Code to perfection',
        caption: 'Coding in computer language is the crux of web development. To design a perfect web page, a structure with an end result is the key and being consistent with the web development process results in a great web design.'
      },
      {
        path: 'services/Embedded.jpg',
        header: 'EMBEDDED SYSTEMS DEVELOPMENT',
        subtitile: 'A coded system for specific function',
        caption: 'Embedded systems are basically hardware that works with an in-built pre-coded software application that is capable of performing functions without manual interaction. This is an ever-evolving branch of software engineering. They are usually created target-oriented to perform a specific task.'
      }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Software {
    padding: 50px 30px 10px 30px;
}
.services .icon-box i {
  float: left;
  font-size: 40px;
  line-height: 0;
}

.services .icon-box .softwarehead {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 25px;
}

.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}
h6.softwaresubhead {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
}
.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>